<template>

    <div class="left_items">
<!--        <left-buttons-comp></left-buttons-comp>-->
        <div style="width: 100%">
            <div class="item_title">
                종목
            </div>
            <div class="item"  :class="{'active':typeId===inplayConst.SPORTS_ID_ALL}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_ALL)">
                <div class="img"><img src="../../assets/images/icon/type/1/all.svg"></div>
                <div class="name">전체</div>
                <div class="gc"> <span class="badge badge-gc">{{alltypecount}}</span></div>
            </div>
            <div class="item" :class="{'active':typeId===inplayConst.SPORTS_ID_SOCCER}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_SOCCER)">
                <div class="img"><img src="../../assets/images/icon/type/1/football-ico.png"></div>
                <div class="name">축구</div>
                <div class="gc"> <span class="badge badge-gc ">{{soccer}}</span></div>
            </div>
            <div class="item" :class="{'active':typeId===inplayConst.SPORTS_ID_BASKETBALL}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_BASKETBALL)">
                <div class="img"><img src="../../assets/images/icon/type/1/basketball-ico.png"></div>
                <div class="name">농구</div>
                <div class="gc"> <span class="badge badge-gc ">{{basketball}}</span></div>
            </div>
            <div class="item" :class="{'active':typeId===inplayConst.SPORTS_ID_BASEBALL}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_BASEBALL)">
                <div class="img"><img src="../../assets/images/icon/type/1/baseball-ico.png"></div>
                <div class="name">야구</div>
                <div class="gc"> <span class="badge badge-gc ">{{baseball}}</span></div>
            </div>
            <div class="item" :class="{'active':typeId===inplayConst.SPORTS_ID_VOLLEYBALL}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_VOLLEYBALL)">
                <div class="img"><img src="../../assets/images/icon/type/1/volleyball-ico.png"></div>
                <div class="name">배구</div>
                <div class="gc"> <span class="badge badge-gc ">{{volleyball}}</span></div>
            </div>
            <div class="item" :class="{'active':typeId===inplayConst.SPORTS_ID_ICEHOKEY}"
                 @click="changeTypeId(inplayConst.SPORTS_ID_ICEHOKEY)">
                <div class="img"><img src="../../assets/images/icon/type/1/hockey-ico.png"></div>
                <div class="name">하키</div>
                <div class="gc"> <span class="badge badge-gc ">{{hockey}}</span></div>
            </div>
            <div class="item_title">
                리그
            </div>
            <div class="item" v-for="(item,index) in leagueList" @click="leagueSelector(item)" v-if="item.isShow"
                 :key="item.id" :class="{'active':item.checked}">
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img src="../../assets/images/icon/type/1/football-ico.png"></div>
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img src="../../assets/images/icon/type/1/baseball-ico.png"></div>
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img src="../../assets/images/icon/type/1/basketball-ico.png"></div>
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img src="../../assets/images/icon/type/1/volleyball-ico.png"></div>
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img src="../../assets/images/icon/type/1/hockey-ico.png"></div>
                <div class="img" v-if="item.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img src="../../assets/images/icon/type/1/usa-football-ico.png"></div>
                <div class="league_img">
                    <img :src="item.imageUrl" alt="" style="margin-left: 5px">
                </div>
                <div class="name2">{{item.leagueName}}</div>
            </div>
        </div>
    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import {mapGetters} from "vuex"
    import {} from "../../store/mutation-types";
    import {getSportsGameCountGroupByType} from "../../network/sportsRequest";
    import LeftButtonsComp from "../LeftButtonsComp";

    export default {
        name: "InplayLeftBarComp",
        components: {LeftButtonsComp},
        props: {
            pleagueList: Array,
            default() {
                return []
            }
        },
        data() {
            return {
                leagueList: this.pleagueList,
                checkedLeagueList: [],
                isShowLeagueTab: false,
                sportsConst,
                inplayConst,
                typeId: 0,
                soccer: 0,
                baseball: 0,
                basketball: 0,
                volleyball: 0,
                hockey: 0,
                alltypecount: 0,
            }
        },
        methods: {
            initLeagues() {
            },
            leagueSelector(item) {
                item.checked = !item.checked
                this.$emit('leagueChanged', item)
            },
            changeTypeId(type) {
                this.typeId = type;
                this.$emit('changeTypeId', type)
            },
            hideLeagueTab() {
                this.isShowLeagueTab = false;
            }
        },
        computed: {
            ...mapGetters({}),

        },
        created() {
            getSportsGameCountGroupByType(sportsConst.INPLAY_YES).then(res => {

                if (res.data.success) {
                    let map = res.data.data;
                    this.soccer = map[inplayConst.SPORTS_ID_SOCCER] !== undefined ? map[inplayConst.SPORTS_ID_SOCCER] : 0
                    this.baseball = map[inplayConst.SPORTS_ID_BASEBALL] !== undefined ? map[inplayConst.SPORTS_ID_BASEBALL] : 0
                    this.basketball = map[inplayConst.SPORTS_ID_BASKETBALL] !== undefined ? map[inplayConst.SPORTS_ID_BASKETBALL] : 0
                    this.volleyball = map[inplayConst.SPORTS_ID_VOLLEYBALL] !== undefined ? map[inplayConst.SPORTS_ID_VOLLEYBALL] : 0
                    this.hockey = map[inplayConst.SPORTS_ID_ICEHOKEY] !== undefined ? map[inplayConst.SPORTS_ID_ICEHOKEY] : 0
                    this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey
                }
            })
            this.initLeagues()
        },/*
        watch: {
            pleagueList: {
                handler() {
                },
                immediate: true,
                deep: false
            }
        }*/
    }
</script>

<style scoped>


    .sports_menu_list i {
        font-size: 14px;
        font-weight: bold;
    }

    .sports_menu_list li .badge {
        opacity: .9 !important;
        font-weight: normal !important;
    }

    @media screen and (max-width: 800px) {

        .sports_menu_list .main_left3 li .name {
            width: 270px !important;
        }
    }


</style>